<template>
    <div class="script-main">
        <div class="script-item">
            <el-table :data="classifiesTable" style="width: 100%; flex: 1" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}"
                      :cell-style="{fontSize: '14px',color: '#333'}"
                      ref="multipleTable"
                      tooltip-effect="dark"
                      @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column prop="index" label="序号" align="left"></el-table-column>
                <el-table-column prop="name" label="脚本名称" align="center"></el-table-column>
                <el-table-column prop="create" label="创建时间"  sortable align="center" width="200"></el-table-column>
                <el-table-column prop="cover_url" label="封面" align="center">
                    <template slot-scope="scope">
                        <div class="coverImg">
                            <img :src="scope.row.cover_url?scope.row.cover_url:require('../../assets/img/image/moren_bg.jpg')">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="classification_name" label="分类" align="center"></el-table-column>
                <el-table-column label="操作" align="center" width="230">
                    <template slot-scope="scope">
<!--                        <el-button size="small" @click="viewScriptData(scope.row)">查看</el-button>-->
                        <el-button size="small" @click="editClassifies(scope.row.id)">编辑</el-button>
                        <el-button size="small" @click="dellData(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="classifiesPages.currentPageNum"
                    :page-size="classifiesPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="classifiesPages.total"
                    @current-change="classifiesCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ScriptModule",
        data() {
            return {
                classifiesTable: [],
                status: '',
                multipleSelectionId: [],
                //分页
                classifiesPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getScriptList();
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            handleSelectionChange(val) {
                this.multipleSelectionId = [];
                for (let i in val) {
                    this.multipleSelectionId.push(val[i].id)
                }
            },
            // 获取脚本
            getScriptList(searchInput) {
                let param = {
                    page: this.classifiesPages.currentPageNum,
                    pageSize: this.classifiesPages.eachPageNum,
                    paging: 1,
                    status: 1
                }
                if (searchInput !== '') {
                    param.name = searchInput;
                }
                this.$http.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.classifiesTable = res.data.data
                            // console.time('global')
                            // this.classifiesTable.forEach((item, index) => {
                            //     item.index = index+1
                            // })
                            // console.timeEnd('global')  //global: 0.01171875 ms

                            // console.time('global')
                            for (let i=0; i<this.classifiesTable.length; i++) {
                                this.$set(this.classifiesTable[i], 'index', i+1)
                            }
                            // console.timeEnd('global')  //global: 0.010986328125 ms
                            this.classifiesPages.total = res.data.total
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },
            //分页
            classifiesCurrentChange(val) {
                this.classifiesPages.currentPageNum = val;
                this.getScriptList()
            },
            // 编辑
            editClassifies(id){
                this.$router.push({
                    path: '/admin/script/index',
                    query: {id: id}
                });
            },
            //查看脚本详情
            // viewScriptData(item){
            //     this.$router.push({
            //         path: '/admin/script/view/creation',
            //         query: {
            //             id: item.id
            //         }
            //     });
            // },
            dellData(id) {
                //    删除数据
                let idArr = [];
                if (id) {
                    //    删除指定 id
                    idArr.push(id)
                } else {
                    if (this.multipleSelectionId.length===0){
                        this.$message({
                            type: "error",
                            message: '请选择脚本',
                            duration: 1000
                        });
                        return;
                    }
                    idArr = this.multipleSelectionId;
                }
                let param = {
                    id: JSON.stringify(idArr)
                }
                this.$confirm('是否删除该脚本，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.delScript, param, res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                    duration: 1000,
                                });
                                this.getScriptList();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000
                                });
                            }
                        },
                        err => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .script-main {
        display: flex;
        flex-direction: column;
        height: 100%;
        .script-item{
            display: flex;
            flex-direction: column;
            height:calc(100vh - 235px);
        }
        .coverImg{
            width:99px;
            height: 57px;
            display: inline-block;
            img{
                width:99px;
                height: 57px;
            }
        }
        .btn-blue-script{
            color:#1E33E4;
            font-size: 14px;
        }
        .btn-red-script{
            color:#F90B32;
            font-size: 14px;
        }
    }
</style>